import { React, useState } from 'react'
import Layout from '../../components/Layout/indexWp'
import SEO from '../../components/SEO'
import styled from '@emotion/styled'

const StyledIframe = styled.iframe`
  height: 100%;
  overflow-y:scroll !important;
  -webkit-overflow-scrolling: touch !important;
`

const Bulletin = ({ data }) => {
  return (
    <Layout>
      <SEO title="주보광고 | 소식 - 죠이플 교회" description="Joy Church updates and bulletin info" />
          <StyledIframe 
            log
            src="https://wp.joyfulchurch.us/bulletin/" 
            style={{ width: '100%', minWidth: '100%'}}
          />
    </Layout>
  )
}

export default Bulletin
